<template>
  <div class="top-distance">
    <div class="role-item-box" v-if="menuList.length > 1">
      <div class="modle-name">权限选择</div>
      <el-checkbox v-model="macheckAll" @change="handleCheckAllChange1"
        >全选</el-checkbox
      >
    </div>
    <el-tree
      :data="managementData"
      show-checkbox
      default-expand-all
      node-key="menuId"
      ref="gltree"
      key="gl"
      highlight-current
      :props="defaultProps"
      @check="handleCheckChange"
      class="tree-box"
    >
    </el-tree>
  </div>
</template>
<script>

import getMenuDataBuild from "../../../../util/menuDataBuild";
export default {
  props: {
    editRoleId: "",
    editMenuType: "",
  },
  watch: {
    editRoleId(val) {
      this.roleId = val;
    },
    editMenuType(val) {
      this.menuType = val;
    },
    selectedMenuList(val) {
      if (val.length == this.menuDataLength - 1) {
        this.macheckAll = true;
      } else {
        this.macheckAll = false;
      }
    },
  },
  data() {
    return {
      roleId: this.editRoleId, //编辑的权限ID
      menuType: this.editMenuType, //菜单类型
      managementData: [], //管理权限
      defaultProps: {
        children: "children",
        label: "menuName",
      },
      macheckAll: false,
      selectedMenuList: [],
      menuDataLength: 0, //菜单数据长度
      menuRoot: {}, //跟节点
      menuList: [], //数据库返回的menu数据
    };
  },
  mounted() {
    this.managementEcho();
  },
  methods: {
    handleCheckChange(data, checkedKeys) {
      this.setSectedMenuList();
    }, //管理权限全选
    handleCheckAllChange1() {
      if (this.macheckAll) {
        this.$refs.gltree.setCheckedNodes(this.managementData);
      } else {
        this.$refs.gltree.setCheckedKeys([]);
      }
      this.setSectedMenuList();
    },
    setSectedMenuList() {
      this.selectedMenuList = [];
      // 管理权限
      // this.$refs.gltree.getCheckedNodes(false,true)
      let gltree = this.$refs.gltree
      //这里需要包涵半选中状态的
      let allMenuIds = [...gltree.getCheckedKeys()||[],...gltree.getHalfCheckedKeys()||[]];
      
      allMenuIds.forEach((val) => {
        this.selectedMenuList.push({ menuId: val });
      });
      this.$emit("change", this.selectedMenuList);
    },
    getSectedMenuList() {
      //...[{menuId:this.menuRoot.menuId}这个是在解决 提交的时候，默认把根节点带上
      //
      return [...this.selectedMenuList, this.menuRoot];
    },
    managementEcho() {
     
    },
    // 获取详情
    getSysRoleDetail() {
      
    },
  },
};
</script>
<style lang="less" scoped>
.tree-box {
  height: 25vh;
  overflow: auto;
}
</style>
