import { render, staticRenderFns } from "./selectRole.vue?vue&type=template&id=3b5aa3c6&scoped=true&"
import script from "./selectRole.vue?vue&type=script&lang=js&"
export * from "./selectRole.vue?vue&type=script&lang=js&"
import style0 from "./selectRole.vue?vue&type=style&index=0&id=3b5aa3c6&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b5aa3c6",
  null
  
)

export default component.exports