<template>
  <div class="add-role">
    <el-form :model="addForm" class="add-form" label-width="90px" size="small">
      <!-- <title-bar title="角色信息"></title-bar> -->
      <el-form-item label="角色名称" class="top-distance" label-width="75px">
        <el-input
          v-model="addForm.roleName"
          style="width: 350px"
          placeholder="请输入"
          clearable
        
        ></el-input>
      </el-form-item>
      <div class="add-box">
        <div class="role-box">
          <div class="management-role">
            <el-tabs v-model="activeName">
             
              <el-tab-pane label="管理权限" name="first">
                <selectRole
                  @change="selectedChangeHandel"
                  ref="apiSelectRole"
                  :edit-role-id="roleId"
                  :edit-menu-type="1"
                ></selectRole>
              </el-tab-pane>
               <el-tab-pane label="app权限" name="second">
                <selectRole
                  @change="selectedChangeHandel"
                  ref="appSelectRole"
                  :edit-role-id="roleId"
                  :edit-menu-type="0"
                ></selectRole>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import selectRole from "./selectRole.vue";
export default {
  props: {
    //编辑类型 true 新增，false 修改
    isAdd: {
      type: Boolean,
      default: true,
    },
    //当前编辑的用户ID
    editRoleId: String,
    //角色ID
    editRoleName: String,
  },
  watch: {
    isAdd: function (val) {
      // this.userId=this.editUserId;
      this.localIsAdd = val;
    },
    editRoleId: function (val) {
      this.roleId = val;
    },
    editRoleName: function (val) {
      this.addForm.roleName = val;
    },
  },
  components: {
    selectRole,
  },
  data() {
    return {
      activeName: "first",
      localIsAdd: this.isAdd, //编辑类型
      rolesTotalCount: 0,
      addForm: {
        roleName: this.editRoleName, //权限名字
      },
      buscheckAll: false,
      macheckAll: false,
      // checkAll1: false,
      isIndeterminate: false,
      businessList: [],
      // defaultProps: {
      //   children: "children",
      //   label: "menuName",
      // },
      // checkedList: [],s
      businesscheckAll: [], //单个业务权限选择
      managementData: [], //管理权限
      managementcheckAll: [], //业务权限选择
      businessData: [], //业务权限
      roleId: this.editRoleId, //权限id
      // listAll: [],
      concatList: [], //业务全选选择
      // glList: [],
      manageList: [],
      chooseIndex: 0,
      ywChooseIndex: 0,
      // allList: [], //全选字段
      allNum: null,
    };
  },
  // watch: {
  //   macheckAll:{
  //     handler() {
  //     },
  //     deep:true
  //   }
  // },
  mounted() {
    // this.roleId = this.$route.query.row.roleId;
    // this.addForm.roleName = this.$route.query.row.roleName;
    // this.roleId = this.$route.query.roleId;
    // this.addForm.roleName = this.$route.query.roleName;
    // this.businessEcho();
    // this.managementEcho();
    //  this.defaultChecked=[];
    //   this.manageList=[];
    //修改
    if (this.localIsAdd == false) {
      //新增
    } else {
      this.roleId = this.$uuidv4().replace(/\-/g, "");
    }
  },
  methods: {
    //某一个选择的所有menuid对象数组
    selectedChangeHandel() {
      this.manageList = [];
      let apiSelectRole = this.$refs.apiSelectRole.getSectedMenuList();
      let appSelectRole = this.$refs.appSelectRole.getSectedMenuList();
      this.manageList = [...apiSelectRole, ...appSelectRole];
    },
    // 全选
    allCheck(all) {
      // item.permissionType = 1;
    },
    inputClick(val) {
      console.log(val);
      if (val.permissionType === 0) {
        val.permissionType = 1;
      } else {
        val.permissionType = 0;
      }
    },
    //业务权限回显
    businessEcho() {
      
    },
    // // 获取详情
    // getSysRoleDetail() {
    //   getSysRoleDetail({ roleId: this.roleId }).then((res) => {
    //     if (res.code === 1) {
    //       // this.listAll = res.data;
    //       this.defaultChecked = res.data.filter(
    //         (res) => res.permissionType == 1
    //       ); //管理
    //       // this.businesscheckAll = res.data.filter(
    //       //   (res) => res.permissionType == 0
    //       // ); //业务
    //       let defaultChecked = this.defaultChecked.map((item) => item.menuId);
    //       // let businesscheckAll = this.businesscheckAll.map(
    //       //   (item) => item.menuId
    //       // );
    //       // console.log(this.defaultChecked, "defaultChecked");
    //       // this.$nextTick(() => {
    //       let glThat = this;
    //       setTimeout(() => {
    //         defaultChecked.forEach((value) => {
    //           glThat.$refs.gltree.setChecked(value, true, false);
    //         });
    //       });
    //       // let that = this;
    //       // setTimeout(() => {
    //       //   businesscheckAll.forEach((value) => {
    //       //     that.$refs.ywtree.setChecked(value, true, false);
    //       //   });
    //       // });
    //       // })
    //     }
    //   });
    // },
    // 管理权限回显
    // managementEcho() {
    //   let data = { menuType: 1 };
    //   getAuthorityByType(data).then((res) => {
    //     if (res.code == 1) {
    //       this.businessList = res.data;
    //       let manageData = [];
    //       for (let i = 0; i < this.businessList.length; i++) {
    //         if (this.businessList[i].parentId == 0) {
    //           manageData.push({
    //             ...this.businessList[i],
    //             children: [],
    //           });
    //         }
    //       }
    //       // console.log(manageData, "11111");
    //       manageData.forEach((item, index) => {
    //         this.businessList.forEach((v) => {
    //           if (item.menuId == v.parentId) {
    //             item.children.push({
    //               ...v,
    //               children: [],
    //             });
    //           }
    //         });
    //       });
    //       // console.log(manageData, "22222");
    //       manageData.forEach((item) => {
    //         item.children.forEach((itemChild, index) => {
    //           // console.log(itemChild, 'aaaaaaaaaaaa')
    //           this.businessList.forEach((v) => {
    //             if (itemChild.menuId == v.parentId) {
    //               itemChild.children.push({
    //                 ...v,
    //                 children: [],
    //               });
    //             }
    //           });
    //         });
    //       });
    //       // console.log(manageData, 888);
    //       this.managementData = manageData;
    //       //如果是修改，则需要回显数据情况
    //       if (this.localIsAdd == false) {
    //         this.getSysRoleDetail();
    //       }
    //       // console.log(this.managementData,"kkkk333")
    //     }
    //   });
    // },
    // handleCheckChange(data, checkedKeys) {
    //   this.glList = checkedKeys.checkedKeys.concat(checkedKeys.halfCheckedKeys);
    // },
    // ywCheckChange(data, checkedKeys) {
    //   this.ywChooseIndex++;
    //   this.concatList = checkedKeys.checkedKeys.concat(
    //     checkedKeys.halfCheckedKeys
    //   );
    // },
    //管理权限全选
    // handleCheckAllChange1() {
    //   this.chooseIndex++;
    //   if (this.macheckAll) {
    //     this.$refs.gltree.setCheckedNodes(this.managementData);
    //     // allList
    //     this.businessList.forEach((item) => {
    //       this.allList.push({ menuId: item.menuId });
    //     });
    //   } else {
    //     this.$refs.gltree.setCheckedKeys([]);
    //     this.allList = [];
    //   }
    //   console.log(this.allList, "lkj");
    //   this.allNum++;
    // },

    // handleCheckedItemChange(value) {
    //   console.log(value,"handlecheck")
    //   this.businesscheckAll = value;
    //   let checkedBusiness = value.length;
    //   this.buscheckAll = checkedBusiness === this.businessData.length;
    //   this.isIndeterminate = checkedBusiness > 0 && checkedBusiness < this.businessData.length;
    // },
    // goBack() {
    //   this.$router.push({
    //     name: "roleManagement",
    //   });
    // },
    // 编辑角色
    editRoles() {
      // this.manageList = [];
      //  let allMenuId = this.businesscheckAll.map(res=>res.menuId)
      // 管理权限
      // let allMenuId = this.$refs.gltree.getCheckedKeys();
      // console.log(allMenuId);
      // // let managementarr = [];

      // allMenuId.forEach((val) => {
      //   this.manageList.push({ menuId: val });
      // });

      // allMenuId.forEach((val) => {
      //   this.businessList.forEach((item) => {
      //     if (val == item.menuId) {
      //       managementarr.push({
      //         menuName: item.label,
      //         menuId: item.menuId,
      //       });
      //     }
      //   });
      // });
      // let manageList = [];
      // if(this.chooseIndex) {
      //   this.manageList = this.allList
      // } else {
      //   if(this.glList.length<=0 && !this.chooseIndex) {
      //     this.manageList =  this.defaultChecked
      //   } else {
      //     this.manageList = this.glList.map(res=>{
      //       return {menuId:res}
      //     })
      //   }
      // }

      // let arrList = [];
      // if(this.concatList.length<=0 && !this.ywChooseIndex) {
      //  arrList =  this.businesscheckAll
      // } else {
      //   arrList = this.concatList.map(res=>{
      //     return {menuId:res}
      //   })
      // }

      this.selectedChangeHandel();
      let data = {
        roleName: this.addForm.roleName,
        roleId: this.roleId,
        // sysRoleMenus: [...arrList, ...managementarr],

        sysRoleMenus: [...this.manageList],
      };

      if (this.localIsAdd == true) {
        // 新增角色
      } else {
        // 编辑角色
      }
    },
  },
};
</script>

<style lang="less" scoped>
.add-box {
  display: flex;
}
.add-role {
  margin-left: 70px;
  .business-authority-box {
    padding: 24px 24px;
    // padding-right: 10px;
    // width: 100%;
    // box-sizing: border-box;
    // height: 270px;
    width: 1400px;
    border: 1px solid #e5e5e5;

    .checkbox-menu {
      display: flex;
      align-items: center;
    }
  }

  .business-authority {
    padding: 0 86px;
    height: 100%;
    overflow-y: auto;
  }

  .role-item {
    display: flex;
    flex-wrap: wrap;
    margin-top: 26px;

    &:first-child {
      margin-top: 0px;
    }

    /deep/.el-checkbox {
      // margin-right: 150px;
      width: 170px;
      margin-bottom: 5px;
    }
  }

  .top-distance {
    margin-top: 26px;
  }

  .top-distance1 {
    margin-top: 42px;
  }

  .ml10 {
    margin-left: 10px;
  }

  .form-btn {
    text-align: center;
    margin-top: 30px;

    /deep/.el-button--mini,
    .el-button--mini.is-round {
      padding: 9px 32px;

      font-size: 14px;
    }
  }

  /* 设置滚动条的样式 */
  ::-webkit-scrollbar {
    width: 8px;
    height: 100%;
    background: #e5e5e5;
    border-radius: 4px;
  }

  /* 滚动槽 */
  ::-webkit-scrollbar-track {
    background: #e5e5e5;
    border-radius: 4px;
  }

  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #bcbcbc;
  }

  .ml30 {
    margin-left: 30px;
  }

  .role-item-box {
    padding-left: 70px;
    display: flex;
  }

  .modle-name {
    width: 70px;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
  }

  .tree-box {
    margin-left: 70px;
    margin-top: 20px;
  }
  .role-box {
    // display: flex;
    // justify-content: space-between;
    // width: 1000px;
    // margin-top:30px;
    .management-role {
      width: 400px;
     
    }
  }
  .input-check {
    display: flex;
    flex-wrap: wrap;
    .input-list {
      width: 200px;
      margin-bottom: 10px;
    }

    .el-icon-check {
      color: #fff;
      background: #14d393;
    }
    .input-name {
      position: absolute;
      font-size: 14px;
      color: #606266;
      padding-left: 10px;
    }
  }
  .input-box {
    display: inline-block;
    position: relative;
    border: 1px solid #dcdfe6;
    border-radius: 2px;
    box-sizing: border-box;
    width: 15px;
    height: 15px;
    background-color: #fff;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      background: #fff;
    }
    &.input-box-checked {
      &::after {
        position: absolute;
        content: "√";
        color: #fff;
        background: #14d393;
        width: 15px;
        height: 15px;
      }
    }
  }
  .input-all {
    font-size: 14px;
    color: #606266;
    padding-left: 10px;
    margin-top: -4px;
  }

  .yu-box {
    width: 500px;
    height: 600px;
    overflow: auto;
    // margin-left: 400px;
  }
}
</style>
