<template>
  <div class="role-management">
    <div class="search-top">
      <el-button type="primary" size="mini" @click="goToAdd"
        >新增角色</el-button
      >
    </div>
    <div style="clear: both"></div>
    <el-table
      :data="tableData"
      style="width: 100%"
      class="mt20r"
      stripe
      border
      :height="tableHeight"
    >
      <el-table-column type="index" label="序号" width="80"> </el-table-column>
      <el-table-column
        prop="roleName"
        label="角色名称"
        width="170"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="roleDesc" label="角色权限" show-overflow-tooltip>
      </el-table-column>
      <el-table-column label="操作" fixed="right" v-slot="scope" width="120">
        <div class="option-list-box">
          <!-- <span class="ml10r green-color" @click="goToEdit(scope.row)"
            >编辑</span
          >
          <span class="ml10r red-color" @click="deleteOption(scope.row)">
            删除</span
          > -->
          <el-link
            :underline="false"
            type="primary"
            @click="goToEdit(scope.row)"
            >编辑</el-link
          >

          <el-link
            :underline="false"
            type="danger"
            class="ml10r"
            @click="deleteOption(scope.row)"
            >删除</el-link
          >
        </div>
      </el-table-column>
    </el-table>
    <div class="page-box mt20r">
      <el-pagination
        background
         @size-change="handleSizeChange"
        @current-change="getTableDataList"
        :current-page.sync="listQuery.current"
        :page-sizes="[10, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 编辑角色信息弹窗  -->
    <el-dialog
      :visible.sync="editRoleDialogVisible"
      width="600px"
      :destroy-on-close="true"
    >
      <span slot="title" v-if="isAdd == true"> 新增角色信息 </span>
      <span slot="title" v-if="isAdd == false"> 编辑角色信息 </span>
      <editRole
        v-if="editRoleDialogVisible"
        :is-add="isAdd"
        :edit-role-id="editRoleId"
        :edit-role-name="editRoleName"
        ref="editRole"
        @success="editSuccessHandel"
      ></editRole>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editRoleDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmEditHandle">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import editRole from "./components/editRole.vue";
export default {
  components: {
    editRole,
  },
  data() {
    return {
      listQuery: {
        current: 1,
        size: 10,
      },
      total: 0,
      tableData: [],
      isAdd: true, //编辑类型 true 新增 false 修改
      editRoleDialogVisible: false, //编辑界面弹窗是否显示
      editRoleId: "", //需要编辑的角色ID
      editRoleName: "", //需要编辑的角色Name
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    handleSizeChange(val) {
      this.listQuery.size = val;
      this.listQuery.current = 1;
      this.getTableData();
    },
    //编辑操作成功
    editSuccessHandel() {
      this.getTableData();
      this.editRoleDialogVisible = false;
    },
    goToAdd() {
      this.editRoleId = "";
      this.editRoleName = "";
      this.isAdd = true;
      this.editRoleDialogVisible = true;
    },
    goToEdit(row) {

      this.isAdd = false;
      this.editRoleId = row.roleId;
      this.editRoleName = row.roleName;
      this.editRoleDialogVisible = true;
    },
    //确定编辑事件
    confirmEditHandle() {
      this.$refs.editRole.editRoles();
    },

    //查询列表
    getTableDataList(val) {
      this.listQuery.current = val;
      this.getTableData();
    },
    //获取数据
    getTableData() {
      
    },
    deleteOption(row) {
      this.$confirm("确定要删除此用户？", "删除用户", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
              this.$message.success("删除");
        })
        .catch(() => {
          
        });
    },
  },
};
</script>

<style lang="less" scoped>
.role-management {
  .search-top {
    height: 40px;
    float: right;
  }
  .option-list-box {
    display: flex;
    span {
      cursor: pointer;
    }
  }
  .page-box {
    text-align: right;
  }
  .ml10r {
    margin-left: 10px;
  }
  // .green-color {
  //   color: @primaryColor;
  // }
  // .red-color {
  //   color: @deleteColor;
  // }
  // /deep/.el-table .cell.el-tooltip__popper{
  //   min-width: 50%;
  // }
}
</style>